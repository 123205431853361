<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>退货地址管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add(null, false)">新增地址</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="公司名" prop="businessName" />
        <el-table-column label="联系人" prop="fullname" />
        <el-table-column label="联系号码" prop="mobilePhone" />
        <el-table-column label="详细地址" prop="address" >
          <template slot-scope="props">
            {{props.row.prov}}{{props.row.city}}{{props.row.area}}{{props.row.address}}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="props">
            <el-button type="primary" @click="add(props.row, true)">编辑</el-button>
            <el-button type="danger" @click="_delete(props.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" @close="editDialogClosed">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-form-item label="公司名" >
          <el-input v-model="businessName" placeholder="公司名" />
        </el-form-item>
        <el-form-item label="联系人" >
          <el-input v-model="fullname" placeholder="联系人" />
        </el-form-item>
        <el-form-item label="联系号码" >
          <el-input v-model="mobilePhone" placeholder="联系号码" />
        </el-form-item>
        <el-form-item label="省" >
          <el-input v-model="prov" placeholder="省" />
        </el-form-item>
        <el-form-item label="市" >
          <el-input v-model="city" placeholder="市" />
        </el-form-item>
        <el-form-item label="区" >
          <el-input v-model="area" placeholder="区" />
        </el-form-item>
        <el-form-item label="详细地址" >
          <el-input v-model="address" placeholder="详细地址" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      businessName: '',
      fullname: '',
      mobilePhone: '',
      prov: '',
      city: '',
      area: '',
      address: '',
      editId: null,
    }
  },
  created () {
    this.GetList();
  },
  methods: {
    GetList () {
      this.$api.QueryRefundAddressList({}).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        this.list = res.data
      })
    },
    _delete(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteRefundAddress({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (row, isEdit) {
      this.editId = null;
      this.businessName = '';
      this.fullname = '';
      this.mobilePhone = '';
      this.prov = '';
      this.city = '';
      this.area = '';
      this.address = '';

      if(isEdit){
        this.editId = row.id;
        this.businessName = row.businessName;
        this.fullname = row.fullname;
        this.mobilePhone = row.mobilePhone;
        this.prov = row.prov;
        this.city = row.city;
        this.area = row.area;
        this.address = row.address;
      }
      this.dialogVisible = true
    },

    // 监听分类编辑对话框的关闭事件
    editDialogClosed () {
      
    },
    // 编辑商品
    save () {
      if(!this.businessName) return this.$message.error('请填写公司名');
      if(!this.fullname) return this.$message.error('请填写联系人');
      if(!this.mobilePhone) return this.$message.error('请填写联系号码');
      if(!this.prov) return this.$message.error('请填写省');
      if(!this.city) return this.$message.error('请填写市');
      if(!this.area) return this.$message.error('请填写区');
      if(!this.address) return this.$message.error('请填写详细地址');
      let promise = null;
      const params = {
        businessName: this.businessName,
        fullname: this.fullname,
        mobilePhone: this.mobilePhone,
        prov: this.prov,
        city: this.city,
        area: this.area,
        address: this.address,
      }
      if(this.editId){
        params.id = this.editId
        promise = this.$api.UpdateRefundAddress(params)
      }else{
        promise = this.$api.AddRefundAddress(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
